import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bulma-components';
import CheckboxWithLabel from '../../../shared/CheckboxWithLabel';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

function TableauForm(props) {
  const baseUrl = props.auth?.base_url || '';
  const siteName = props.auth?.site_name || '';
  const skipSslCheck = props.skipSslCheck || false;

  return (
    <React.Fragment>
      <Form.Field>
        <Form.Label htmlFor="base-url">Tableau Base URL</Form.Label>
        <Form.Help>
          This is the URL you see when you go to your Tableau instance. Example: https://10ay.online.tableau.com
        </Form.Help>
        <Form.Control>
          <Form.Input
            placeholder="Tableau Base URL"
            type="text"
            name="base_url"
            onChange={props.onAuthChange}
            value={baseUrl}
            id="base-url"
          />
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Form.Label htmlFor="site-name">Tableau Site Name</Form.Label>
        <Form.Help>
          Your Tableau site name. This can be found in the URL of your Tableau Instance:
          https://online.tableau.com/#/site/&lt;site-name&gt;/workbooks
        </Form.Help>
        <Form.Control>
          <Form.Input
            placeholder="Tableau Site Name (optional for Tableau Server with a default site)"
            type="text"
            name="site_name"
            onChange={props.onAuthChange}
            value={siteName}
            id="site-name"
          />
        </Form.Control>
      </Form.Field>
      {props.flags.enableSkipSslCheckForTableau && (
        <Form.Field>
          <CheckboxWithLabel
            checked={skipSslCheck}
            id="skip_ssl_check"
            label="Skip SSL Certificate Check"
            name="skipSslCheck"
            onChange={props.onChange}
          />
        </Form.Field>
      )}
    </React.Fragment>
  );
}

TableauForm.propTypes = {
  auth: PropTypes.object,
  onAuthChange: PropTypes.func,
  onChange: PropTypes.func,
  onPasswordAuthChange: PropTypes.func,
  testResult: PropTypes.object,
  selectedDataSource: PropTypes.object,
  isLoading: PropTypes.bool,
  testIsLoading: PropTypes.bool,
  testDataSource: PropTypes.func,
  deleteDataSource: PropTypes.func,
  skipSslCheck: PropTypes.bool,
  flags: PropTypes.object,
};

export default withLDConsumer()(TableauForm);
