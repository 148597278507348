import React, { useState, useRef, useEffect } from 'react';
import Tippy from '@tippyjs/react/headless';
import EmailAttachmentPopUpBody from './EmailAttachmentPopUpBody';
import paperclip from '../../../images/paperclip.svg';
import WithInstantSearch from 'components/shared/search/WithInstantSearch';
import PropTypes from 'prop-types';
import Constants from '../../Constants';
import Button from '../../lib/Button';
import { useTemplates } from 'lib/hooks/useTemplate';

function EmailAttachmentPopUp(props) {
  const [tipInstance, setTipInstance] = useState(null);
  const [width, setWidth] = useState(0);
  const onTipMount = (instance) => {
    setTipInstance(instance);
  };
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    const getwidth = () => {
      setWidth(ref.current.offsetWidth);
    };
    window.addEventListener('resize', getwidth);
    return () => window.removeEventListener('resize', getwidth);
  }, []);
  return (
    <div className="add-attachment-popup" ref={ref}>
      <Tippy
        render={() => (
          <EmailAttachmentPopUpBody
            width={width}
            searchState={props.searchState}
            searchAttributes={props.searchAttributes}
            onSearchStateChange={props.onSearchStateChange}
            isSearching={props.isSearching}
            templates={props.templates.filter(
              (template) =>
                template.source_type !== Constants.TEMPLATE_SOURCE_TYPES.EMAIL &&
                !Constants.TEMPLATE_SOURCE_TYPES.SPREADSHEET_TYPES.includes(template.source_type),
            )}
            entityType={props.entityType}
            onTemplateUpdateWithServerCall={props.onTemplateUpdateWithServerCall}
            currentTemplate={props.currentTemplate}
            tipInstance={tipInstance}
          />
        )}
        placement="top"
        interactive={true}
        trigger="click"
        onMount={onTipMount}
      >
        <div>
          <Button category="secondary" width="fullWidth">
            <img src={paperclip} className="add-attachment-img" />
            Add Email Attachment
          </Button>
        </div>
      </Tippy>
    </div>
  );
}

EmailAttachmentPopUp.propTypes = {
  searchAttributes: PropTypes.object,
  onSearchStateChange: PropTypes.func,
  searchState: PropTypes.object,
  isSearching: PropTypes.bool,
  templates: PropTypes.array,
  currentTemplate: PropTypes.object,
  onTemplateUpdateWithServerCall: PropTypes.func,
  entityType: PropTypes.string,
};

const EmailAttachmentPopUpWrapper = (props) => {
  const { elements: templates } = useTemplates(0, Constants.PAGE_SIZE, null);
  return <EmailAttachmentPopUp {...props} templates={templates || []} />;
};

export default WithInstantSearch(EmailAttachmentPopUpWrapper);
