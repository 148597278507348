import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PopupMenu from '../shared/PopupMenu';

function DropdownMenu({ children, dropdownTrigger, isRight, active, onClose }) {
  const [menu, setMenu] = useState(null);

  const timeoutRef = useRef();
  const triggerRef = useRef();

  const isHoverable = active === undefined;
  useEffect(() => {
    if (!isHoverable && menu) {
      if (active) {
        menu.open();
      } else {
        menu.close();
      }
    }
  }, [active]);

  const x_y = isRight ? 'x' : 'y';

  const onMouseEnter = () => {
    if (isHoverable && menu) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      menu.open();
    }
  };

  const onMouseLeave = () => {
    if (isHoverable && menu) {
      timeoutRef.current = setTimeout(menu.close, 100);
    }
  };

  const refHandler = useCallback((menuRefCurrent) => {
    if (menuRefCurrent && !menu) {
      setMenu(menuRefCurrent);
    }
  });
  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="content-center">
      <span ref={triggerRef}>{dropdownTrigger}</span>
      <PopupMenu ref={refHandler} position={x_y} anchorRef={triggerRef} onClose={onClose}>
        <div className="min-w-60 bg-matik-white rounded border border-grey-300 shadow-m">{children}</div>
      </PopupMenu>
    </div>
  );
}

DropdownMenu.propTypes = {
  active: PropTypes.bool,
  dropdownTrigger: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func,
  className: PropTypes.string,
  isRight: PropTypes.bool,
  isUp: PropTypes.bool,
};

export default DropdownMenu;
