import React from 'react';
import { each, map } from 'lodash';
import Pluralize from 'pluralize';
import { EVENT_TYPE_VALUES, READ_STATE_VALUES } from './Constants';
import Constants from '../../Constants';
import TextOverflowWithTooltip from '../TextOverflowWithTooltip';
import { ReactComponent as TemplateIcon } from '../../../svg/templates.svg';
import { ReactComponent as PresentationIcon } from '../../../svg/presentations.svg';
import { ReactComponent as TemplateErrorIcon } from '../../../svg/template_with_error.svg';
import { ReactComponent as TemplateWarningIcon } from '../../../svg/template_with_warning.svg';
import Icon from '../../lib/Icon';
import useTemplate from 'lib/hooks/useTemplate';
import PropTypes from 'prop-types';
import { useAccessor } from 'lib/hooks/useAccess';
import UserAvatar from 'react-user-avatar';
import IconPill from 'components/lib/IconPill';

class BaseNotification {
  constructor(bundle, role, bulkMarkRead, setIsActive) {
    if (this.constructor === BaseNotification) {
      throw new Error('Abstract classes cannot be instantiated.');
    }
    this.bundle = bundle;
    this.head = bundle.at(0);
    this.isOne = bundle.length === 1;
    this.role = role;
    this.markRead = bulkMarkRead;
    this.setIsActive = setIsActive;
  }

  get icon() {
    throw new Error("Accessor field 'get icon()' must be implemented.");
  }

  get title() {
    throw new Error("Accessor field 'get title()' must be implemented.");
  }

  get messageBody() {
    throw new Error("Accessor field 'get messageBody()' must be implemented.");
  }

  get primaryActionText() {
    throw new Error("Accessor field 'get primaryActionText()' must be implemented.");
  }

  primaryAction = () => {
    throw new Error("Method 'primaryAction()' must be implemented.");
  };
}

class AccessChangedNotification extends BaseNotification {
  constructor(bundle, role, bulkMarkRead, setIsActive) {
    super(bundle, role, bulkMarkRead, setIsActive);
    this.eventType = 'access';
  }

  get icon() {
    switch (this.head.event.event_parameters.item_type) {
      case Constants.ItemTypes.TEMPLATE:
        return <TemplateIcon className="icon" />;
      case Constants.ItemTypes.PRESENTATION:
        return <PresentationIcon className="icon" />;
      default:
        return <Icon name="dynamic_content" />;
    }
  }

  get title() {
    if (this.isOne) {
      return `A ${this.head.event.event_parameters.item_type} was shared with you!`;
    }
    return `${Pluralize(
      `new ${this.head.event.event_parameters.item_type}`,
      this.bundle.length,
      true,
    )} were shared with you!`;
  }

  get messageBody() {
    if (this.isOne) {
      return (
        <>
          {`${this.head.templateData.grantor_name} invited you to ${this.head.event.event_parameters.permission} `}
          <TextOverflowWithTooltip className="text-overflow-ellipsis" text={this.head.templateData.item_name} />
        </>
      );
    }
    return <div>Check them out to save even more time!</div>;
  }

  get primaryActionText() {
    return 'View';
  }

  primaryAction = (e, history) => {
    e.preventDefault();
    let url =
      this.role === Constants.PRODUCER_ROLE ? '/templates/menu/new_for_me' : '/create/templates/menu/new_for_me';
    if (this.isOne) {
      url =
        this.role === Constants.PRODUCER_ROLE
          ? `/templates/${this.head.event.event_parameters.item_id}`
          : `/create/templates/${this.head.event.event_parameters.item_id}`;
    }
    history.push(url);
    this.setIsActive(false);
    if (this.head.event.read_state === READ_STATE_VALUES.unread) {
      this.markRead(map(this.bundle, (notification) => notification.event.id));
    }
  };
}
AccessChangedNotification.canBeBundled = true;

class PresentationErrorsNotification extends BaseNotification {
  constructor(bundle, role, bulkMarkRead, setIsActive) {
    super(bundle, role, bulkMarkRead, setIsActive);
    this.isWarning = this.head.templateData.level === Constants.LOG_LEVEL.warning;
    this.eventType = this.isWarning ? 'warning' : 'failure ';
  }

  get icon() {
    if (this.head.templateData.level === Constants.LOG_LEVEL.warning) {
      return <TemplateWarningIcon className="icon" />;
    } else {
      return <TemplateErrorIcon className="icon" />;
    }
  }

  get title() {
    if (this.isOne) {
      if (this.isWarning) {
        return `Oh, ${Pluralize('warning', this.head.templateData.count, true)} need your attention!`;
      } else {
        return 'Ooops, there is trouble!';
      }
    } else {
      if (this.isWarning) {
        return `${Pluralize('presentation', this.bundle.length, true)} with warnings!`;
      } else {
        return `${Pluralize('failed presentation', this.bundle.length, true)}!`;
      }
    }
  }

  get messageBody() {
    const yourTemplate = (
      <div className="text-overflow-ellipsis">
        Your template{' '}
        <u data-tooltip-id="matik-tooltip" data-tooltip-content={this.head.templateData.template_name}>
          {this.head.templateData.template_name}
        </u>
      </div>
    );
    if (this.isOne) {
      if (this.isWarning) {
        return (
          <>
            {yourTemplate}
            caused {Pluralize('warning', this.head.templateData.count, true)} while generating
          </>
        );
      } else {
        return (
          <>
            {yourTemplate}
            failed to generate and needs your attention
          </>
        );
      }
    }
    const templateNames = {};
    each(this.bundle, (notification) => {
      if (templateNames[notification.templateData.template_name]) {
        templateNames[notification.templateData.template_name] += notification.templateData.count;
      } else {
        templateNames[notification.templateData.template_name] = notification.templateData.count;
      }
    });
    if (Object.keys(templateNames).length === 1) {
      if (this.isWarning) {
        return (
          <>
            {yourTemplate}
            caused {Pluralize('warning', templateNames[this.head.templateData.template_name], true)} in{' '}
            {Pluralize('presentation', this.bundle.length, true)}
          </>
        );
      } else {
        return (
          <>
            {yourTemplate}
            caused {Pluralize('failed presentation', this.bundle.length, true)}
          </>
        );
      }
    } else {
      if (this.isWarning) {
        return <>{Pluralize('presentation', this.bundle.length, true)} contain warnings</>;
      } else {
        return <>{Pluralize('presentation', this.bundle.length, true)} failed to generate</>;
      }
    }
  }

  get primaryActionText() {
    return 'Review';
  }

  primaryAction = (e, history) => {
    e.preventDefault();
    let url = this.role === Constants.PRODUCER_ROLE ? '/presentations/' : '/create/presentations/';
    if (this.isOne) {
      url += `${this.head.event.event_parameters.presentation_id}?logs=true`;
    } else {
      const templateNames = new Set();
      each(this.bundle, (notification) => {
        templateNames.add(notification.templateData.template_name);
      });
      templateNames.forEach((name) => {
        if (url.at(-1) === '/') {
          url += `?template=${encodeURI(name)}`;
        } else {
          url += `&template=${encodeURI(name)}`;
        }
      });
    }
    history.push(url);
    this.setIsActive(false);
    if (this.head.event.read_state === READ_STATE_VALUES.unread) {
      this.markRead(map(this.bundle, (notification) => notification.event.id));
    }
  };
}
PresentationErrorsNotification.canBeBundled = true;

function TemplateName({ id }) {
  const { isPending, data: template, isError } = useTemplate(id);
  if (isPending) {
    return '';
  }
  if (isError) {
    return `Template ${id}`;
  }
  return template.name;
}
TemplateName.propTypes = {
  id: PropTypes.number,
};

function UserName({ id }) {
  const { data: user, isError } = useAccessor('user', id);
  if (user) {
    return user.name;
  }
  if (isError) {
    return `User ${id}`;
  }
  return '';
}
UserName.propTypes = {
  id: PropTypes.number,
};

function UserIcon({ id }) {
  const { data: user, isError } = useAccessor('user', id);
  if (user) {
    return (
      <UserAvatar
        colors={Constants.AVATAR_COLORS}
        src={user.photo_url}
        name={user.name ?? user.email}
        size={40}
        className="text-matik-white"
      />
    );
  }
  if (isError) {
    return `User ${id}`;
  }
  return '';
}
UserIcon.propTypes = {
  id: PropTypes.number,
};

class TemplateAssetAccessRequestedNotification extends BaseNotification {
  constructor(bundle, role, bulkMarkRead, setIsActive) {
    super(bundle, role, bulkMarkRead, setIsActive);
  }

  get icon() {
    const { requesting_user_id } = this.head.event.event_parameters;
    return <UserIcon id={requesting_user_id} />;
  }

  get title() {
    const { requesting_user_id } = this.head.event.event_parameters;
    return (
      <span>
        <UserName id={requesting_user_id} /> requested access from you
      </span>
    );
  }

  get messageBody() {
    const { missing_accessor_count, template_id } = this.head.event.event_parameters;
    return (
      <div>
        {missing_accessor_count} {missing_accessor_count === 1 ? 'user is' : 'users are'} not able to generate &quot;
        <TemplateName id={template_id} />
        &quot; because of missing access to assets.
      </div>
    );
  }

  get primaryActionText() {
    return 'Grant Access';
  }

  primaryAction = (e, history) => {
    e.preventDefault();
    const { template_id, requesting_user_id } = this.head.event.event_parameters;
    history.push(`/templates/${template_id}?ruid=${requesting_user_id}`);
    this.setIsActive(false);
    if (this.head.event.read_state === READ_STATE_VALUES.unread) {
      this.markRead([this.head.event.id]);
    }
  };
}
TemplateAssetAccessRequestedNotification.canBeBundled = false;

class TemplateAssetAccessGrantedNotification extends BaseNotification {
  constructor(bundle, role, bulkMarkRead, setIsActive) {
    super(bundle, role, bulkMarkRead, setIsActive);
  }

  get icon() {
    const { granting_user_id } = this.head.event.event_parameters;
    return <UserIcon id={granting_user_id} />;
  }

  get title() {
    return 'Access permission granted';
  }

  get messageBody() {
    const { template_id } = this.head.event.event_parameters;
    return (
      <div>
        Everyone &quot;
        <TemplateName id={template_id} />
        &quot; is shared with is now able to generate.
      </div>
    );
  }

  get primaryActionText() {
    return 'View Template';
  }

  primaryAction = (e, history) => {
    e.preventDefault();
    const { template_id, template_name } = this.head.event.event_parameters;
    history.push(
      this.role === Constants.PRODUCER_ROLE ? `/templates/${template_id}` : `/create/templates/${template_name}`,
    );
    this.setIsActive(false);
    if (this.head.event.read_state === READ_STATE_VALUES.unread) {
      this.markRead([this.head.event.id]);
    }
  };
}
TemplateAssetAccessGrantedNotification.canBeBundled = false;

function NarrativeIcon({ type }) {
  return <IconPill color="green" size="l" iconName={type} iconTheme="filled" />;
}
NarrativeIcon.propTypes = {
  type: PropTypes.oneOf(['document', 'presentation', 'spreadsheet', 'email']),
};

class NarrativeGeneratedNotification extends BaseNotification {
  constructor(bundle, role, bulkMarkRead, setIsActive) {
    super(bundle, role, bulkMarkRead, setIsActive);
  }

  get icon() {
    if (this.isOne) {
      return <NarrativeIcon type={this.head.templateData.narrative_type} />;
    } else {
      const countsByType = {};
      this.bundle.forEach((item) => {
        const count = countsByType[item.templateData.narrative_type] || 0;
        countsByType[item.templateData.narrative_type] = count + 1;
      });
      let maxCount = -1;
      let mostOfType = this.head.templateData.narrative_type;
      Object.entries(countsByType).forEach(([type, count]) => {
        if (count > maxCount) {
          maxCount = count;
          mostOfType = type;
        }
      });
      return <NarrativeIcon type={mostOfType} />;
    }
  }

  get title() {
    if (this.isOne) {
      return `Your ${this.head.templateData.narrative_type} is ready!`;
    } else {
      return 'Your items have generated!';
    }
  }

  get messageBody() {
    if (this.isOne) {
      return <div>Matik finished generating {this.head.templateData.template_name}</div>;
    } else {
      return (
        <div>
          Matik finished generating {this.bundle.length} item{this.bundle.length !== 1 ? 's' : ''}
        </div>
      );
    }
  }

  get primaryActionText() {
    return 'View';
  }

  primaryAction = (e, history) => {
    e.preventDefault();

    let url = this.role === Constants.PRODUCER_ROLE ? '/presentations/' : '/create/presentations/';
    if (this.isOne) {
      url += `${this.head.event.event_parameters.presentation_id}`;
    } else {
      const templateNames = new Set();
      each(this.bundle, (notification) => {
        templateNames.add('template=' + encodeURI(notification.templateData.template_name));
      });
      url += '?' + [...templateNames].join('&');
    }
    history.push(url);
    this.setIsActive(false);
    if (this.head.event.read_state === READ_STATE_VALUES.unread) {
      this.markRead(map(this.bundle, (notification) => notification.event.id));
    }
  };
}
NarrativeGeneratedNotification.canBeBundled = true;

export const Renderer = {
  [EVENT_TYPE_VALUES.accessChanged]: AccessChangedNotification,
  [EVENT_TYPE_VALUES.presentationErrors]: PresentationErrorsNotification,
  [EVENT_TYPE_VALUES.templateAssetAccessRequested]: TemplateAssetAccessRequestedNotification,
  [EVENT_TYPE_VALUES.templateAssetAccessGranted]: TemplateAssetAccessGrantedNotification,
  [EVENT_TYPE_VALUES.narrativeGenerated]: NarrativeGeneratedNotification,
};
