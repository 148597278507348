import React from 'react';
import PropTypes from 'prop-types';

function BannerSticky({ actionOnClick, actionText, content, icon, subContent, theme }) {
  let backgroundColor = 'yellow-100';
  switch (theme) {
    case 'error':
      backgroundColor = 'red-100';
      break;
    case 'info':
      backgroundColor = 'blue-100';
      break;
    case 'success':
      backgroundColor = 'green-100';
      break;
  }
  return (
    <div className={`flex justify-center items-center min-h-10 gap-2 bg-${backgroundColor}`}>
      {icon}
      {content && <div>{content}</div>}
      {subContent && (
        <>
          •<div className="font-light">{subContent}</div>
        </>
      )}
      {actionText && (
        <>
          •
          <button type="button" onClick={actionOnClick} className="font-light underline">
            {actionText}
          </button>
        </>
      )}
    </div>
  );
}

BannerSticky.propTypes = {
  actionOnClick: PropTypes.func,
  actionText: PropTypes.string,
  content: PropTypes.node,
  icon: PropTypes.node,
  subContent: PropTypes.node,
  theme: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
};

export default BannerSticky;
