import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FileViewer from '../../consumer/FileViewer';
import Constants from 'components/Constants';

class PresentationViewer extends Component {
  render() {
    const templateType =
      this.props.presentation?.presentation_type === Constants.TEMPLATE_SOURCE_TYPES.EMAIL ? 'email' : 'presentation';
    return (
      <React.Fragment>
        {!this.props.presentation &&
          this.props.template.source_type !== Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS && (
            <div className="bottom-border">
              <p className="paxl mll">{this.props.template.description}</p>
            </div>
          )}
        <FileViewer presentation={this.props.presentation} template={this.props.template} frameClass={templateType} />
      </React.Fragment>
    );
  }
}

PresentationViewer.propTypes = {
  presentation: PropTypes.object,
  template: PropTypes.object,
};

export default PresentationViewer;
