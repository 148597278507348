import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ReactComponent as PptIcon } from 'images/ppt_icon.svg';
import microsoft365_drive_logo from 'images/microsoft365_drive_logo.png';
import loading_animation from 'images/loading.gif';
import Icon from 'components/lib/Icon';
import DropdownMenu from '../../lib/DropdownMenu';
import Button from '../../lib/Button';
import Constants from 'components/Constants';
import utils from 'lib/utils';
import { MConfirmHtml } from '../../shared/Alerts';
import MicrosoftPicker from 'components/shared/MicrosoftPicker';
import { openFullScreenModal } from 'redux/ui/action';
import GenerationSettingsModal from './GenerationSettingsModal';
import ListItem from '../../lib/ListItem';
import DropdownMenuItem from '../../lib/DropdownMenuItem';

function TemplateActionDropdown({
  setIsLibrary,
  canEdit,
  syncTemplate,
  onTemplateDelete,
  onTemplateArchive,
  convertTemplate,
  containsLibrarySlide,
  currentTemplate,
  orderedInputs,
  onTemplateUpdateWithServerCall,
}) {
  const [active, setActive] = useState(false);
  const [linkIsLoading, setLinkIsLoading] = useState(false);
  const pickerRef = useRef(null);
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);

  const onTemplateDropdownAction = (action) => {
    if (action === 'delete' && canEdit) {
      onTemplateDelete(currentTemplate);
    } else if (action === 'sync' && canEdit) {
      syncTemplate();
    } else if (action === 'open') {
      let url = currentTemplate.source.url;
      if (
        currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
        currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
      ) {
        setLinkIsLoading(true);
        utils.openTemplateFileInNewTab(null, currentTemplate, () => {
          setLinkIsLoading(false);
        });
        return;
      }
      window.open(url, '_blank', 'noopener,noreferrer');
    } else if (action === 'library' && canEdit) {
      setIsLibrary(!currentTemplate.is_library);
    } else if (action === 'archive' && canEdit) {
      onTemplateArchive(currentTemplate);
    } else if (action === 'convert') {
      MConfirmHtml(
        'Convert Template To Microsoft 365',
        'Select the folder where you would like your PowerPoint template to be saved. Matik will upload a copy of your PowerPoint to this folder and then sync this template to the cloud-hosted file. This cannot be undone.',
        'info',
        (confirmed) => {
          if (confirmed) {
            pickerRef.current?.click();
          }
        },
        'Select Folder',
        'Cancel',
        null,
        'no-perms-alert',
      );
    } else if (action === 'settings') {
      dispatch(openFullScreenModal('generationSettingsModal'));
    }
  };

  const handle365SelectFolder = ({ items }) => {
    convertTemplate(items[0]);
  };

  let notPublishableReason = '';
  let isPublishable = !!canEdit;
  if (canEdit) {
    if (Constants.TEMPLATE_SOURCE_TYPES.DOCUMENT_TYPES.includes(currentTemplate.source_type)) {
      isPublishable = false;
      notPublishableReason = 'Cannot publish documents as libraries';
    } else if (currentTemplate.is_sub_template) {
      isPublishable = false;
      notPublishableReason = 'Cannot publish sub templates as libraries';
    } else if (containsLibrarySlide && !currentTemplate.is_library) {
      isPublishable = false;
      notPublishableReason = 'Cannot publish templates that include library slides as libraries';
    }
  }

  const openIcon =
    currentTemplate.source_type === 'powerpoint' ? (
      linkIsLoading ? (
        <img className="w-5 h-5 m-0 p-0 border-0 bg-transparent" src={loading_animation}></img>
      ) : (
        <PptIcon className="icon-size-20" />
      )
    ) : (
      <Icon name="open" size={20} />
    );

  return (
    <>
      <DropdownMenu
        dropdownTrigger={
          <Button category="secondary" onClick={() => setActive(!active)}>
            <Icon name="more_horizontal" size={20} />
          </Button>
        }
        active={active}
        onClose={() => setActive(false)}
      >
        <DropdownMenuItem>
          <ListItem
            id="open"
            icon={openIcon}
            onClick={() => onTemplateDropdownAction('open')}
            title={
              currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES
                ? 'Open in Google Slides'
                : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS
                ? 'Open in Google Docs'
                : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS
                ? 'Open in Google Sheets'
                : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
                ? 'Open in Word'
                : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD
                ? 'Download Word'
                : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT
                ? 'Download PowerPoint'
                : 'Open in PowerPoint'
            }
          />
        </DropdownMenuItem>
        {canEdit && !currentTemplate.is_sub_template && (
          <DropdownMenuItem>
            <ListItem
              id="sync"
              onClick={() => onTemplateDropdownAction('sync')}
              icon={<Icon name="arrow_sync_circle" size={20} />}
              title={
                currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SLIDES ||
                currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_DOCS ||
                currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.GOOGLE_SHEETS
                  ? 'Sync from Google Drive'
                  : currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT_365 ||
                    currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.WORD_365
                  ? 'Sync from Microsoft 365'
                  : 'Sync Template'
              }
            />
          </DropdownMenuItem>
        )}
        {canEdit && (
          <DropdownMenuItem>
            <ListItem
              id="archive"
              onClick={() => onTemplateDropdownAction('archive')}
              icon={<Icon name="archive" size={20} />}
              title="Archive Template"
              tooltip="Archive this template to hide it from end users."
            />
          </DropdownMenuItem>
        )}
        {canEdit && (
          <DropdownMenuItem>
            <ListItem
              id="settings"
              onClick={() => onTemplateDropdownAction('settings')}
              icon={<Icon name="settings" size={20} />}
              title="Generation Settings"
            />
          </DropdownMenuItem>
        )}
        {canEdit && (
          <>
            <hr className="my-1" />
            <DropdownMenuItem>
              <ListItem
                id="library"
                onClick={() => onTemplateDropdownAction('library')}
                icon={<Icon name="library" size={20} />}
                status={isPublishable ? 'default' : 'disabled'}
                title={currentTemplate.is_library ? 'Unpublish as Library' : 'Publish as Library'}
                tooltip={notPublishableReason ?? undefined}
              />
            </DropdownMenuItem>
          </>
        )}
        {canEdit && (
          <>
            <hr className="my-1" />
            {currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT && (
              <DropdownMenuItem>
                <ListItem
                  id="convert"
                  onClick={() => onTemplateDropdownAction('convert')}
                  icon={
                    <img
                      src={microsoft365_drive_logo}
                      className="w-5 h-5 m-0 p-0 border-0"
                      alt="Convert PowerPoint"
                    ></img>
                  }
                  title="Convert to Microsoft 365"
                />
              </DropdownMenuItem>
            )}
            <DropdownMenuItem>
              <ListItem
                id="delete"
                onClick={() => onTemplateDropdownAction('delete')}
                icon={<Icon name="trash_can" size={20} />}
                title="Delete Template"
              />
            </DropdownMenuItem>
          </>
        )}
      </DropdownMenu>
      {canEdit && currentTemplate.source_type === Constants.TEMPLATE_SOURCE_TYPES.POWERPOINT && (
        <MicrosoftPicker
          className="hidden"
          onPicked={handle365SelectFolder}
          pickerMode="folders"
          buttonRef={pickerRef}
        />
      )}
      <GenerationSettingsModal
        show={ui.fullScreenModal?.name === 'generationSettingsModal'}
        currentTemplate={currentTemplate}
        orderedInputs={orderedInputs}
        onTemplateUpdateWithServerCall={onTemplateUpdateWithServerCall}
      />
    </>
  );
}

TemplateActionDropdown.propTypes = {
  setIsLibrary: PropTypes.func,
  syncTemplate: PropTypes.func,
  onTemplateDelete: PropTypes.func,
  onTemplateArchive: PropTypes.func,
  convertTemplate: PropTypes.func,
  containsLibrarySlide: PropTypes.bool,
  currentTemplate: PropTypes.object,
  canEdit: PropTypes.bool,
  orderedInputs: PropTypes.array,
  onTemplateUpdateWithServerCall: PropTypes.func,
};

export default TemplateActionDropdown;
