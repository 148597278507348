import Constants from 'components/Constants';
import { useTemplateContent } from './hooks/useTemplate';

export function isLoopable(input) {
  return (
    (input?.type === Constants.InputTypes.STRING || input?.type === Constants.InputTypes.DATE) &&
    input?.source_type !== Constants.InputSources.MATIK_USER
  );
}

const LOOP_COLORS = ['blue', 'red', 'purple', 'green', 'yellow'];

export const getLoopedInfo = (input, loops) => {
  const i = loops?.findIndex((loop) => loop.parameter === input.id);
  if (i > -1) {
    return {
      loop: loops[i],
      color: LOOP_COLORS[i % LOOP_COLORS.length],
    };
  } else {
    return null;
  }
};

export const getLoopedSlideInfo = (slide, loops) => {
  const i = loops?.findIndex((loop) => loop.slides.some((s) => s.id === slide.id));
  if (i > -1) {
    return {
      loop: loops[i],
      color: LOOP_COLORS[i % LOOP_COLORS.length],
    };
  } else {
    return null;
  }
};

/** Check to see if the input is associated with slides that already are looped on
 * other inputs. The logic ignores any loops already related to this input.
 *
 * @param {*} input
 * @param {*} template
 */
export const hasSlidesWithLoops = (input, template) => {
  if (!template) {
    return false;
  }

  // Check to see if the selected input is present on slides that already have a loop.
  const slidesIdsWithLoops = template.slide_loops.flatMap((loop) => {
    return loop.parameter === input.id ? [] : loop.slides.map((s) => s.id);
  });

  const inputOnSlideIds = input.slideNums?.map((index) => template.slides[index - 1]?.id) || [];
  return inputOnSlideIds.some((id) => slidesIdsWithLoops.indexOf(id) > -1);
};

/** Check to see if the given input is used as input for conditional content on the template */
export const useIsConditionalContentInput = (inputId, templateId) => {
  const { isPending, data } = useTemplateContent(templateId);

  let isConditionalInput;
  if (!isPending && data) {
    isConditionalInput = data.content_by_slide.some((content) => {
      return (
        content.dynamic_content_type === 'conditional' &&
        Object.values(content.parameters).some((param) => param.id === inputId)
      );
    });
  }

  return [isConditionalInput, isPending];
};
