import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, find } from 'lodash';
import API from '../../../lib/api';
import { Tile } from 'react-bulma-components';
import { Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import Constants from '../../Constants';
import { MAlert, MConfirm, MProductUpsell } from '../../shared/Alerts';
import InnerDynamicContentForm from './forms/InnerDynamicContentForm';
import MatikContentForm from './forms/MatikContentForm';
import { mapDispatchToProps as mapUiDispatchToProps } from 'redux/ui/dispatchers';
import * as notificationDispatchers from '../../../redux/notifications/dispatchers';
import ConditionalContentForm from './forms/ConditionalContentForm';
import InputModal from './InputModal';
import ContentTypeForm from './forms/ContentTypeForm';
import CopyModal from './CopyModal';
import FormHeader from '../../shared/FormHeader';
import AccessManager from '../../../lib/AccessManager';
import { UserContext } from '../../UserContext';
import PageLoader from '../../shared/PageLoader';
import ReactDOMServer from 'react-dom/server';
import UpsellGenericModal from '../../payments/UpsellGenericModal';
import teams from '../../../lib/teams';
import utils from 'lib/utils';
import { mapUiStateToProps } from 'redux/ui/stateMappers';
import FormSkeletonLoader from './FormSkeletonLoader';
import InputFormSidepane from './InputFormSidepane';
import DynamicContentFormMetadata from './forms/DynamicContentFormMetadata';
import MatikAutoInsightsForm from './forms/MatikAutoInsightsForm';
import DataSourceFormWrapper from './forms/DataSourceFormWrapper';
import { validatePowerBISemanticModelQuery, validatePowerBIReportQuery } from './forms/PowerBIForm';
import FormChange from '../../../lib/formChange';
import useTemplate, { useTemplateContent } from 'lib/hooks/useTemplate';
import { DynamicContentContext } from 'components/producer/dynamicContent/DynamicContentContext';
import useAccesses from 'lib/hooks/useAccess';
import {
  useAllDynamicContentById,
  useDynamicContentMutator,
  useDynamicContentTemplates,
} from '../../../lib/hooks/useDynamicContent';
import { useQueryInputList } from '../../../lib/hooks/useInputList';
import FixedFormButtons from '../../shared/FixedFormButtons';
import inputs from '../../../lib/inputs';

const DynamicContentFormStates = {
  CHOOSE_METHOD: 'CHOOSE_METHOD',
  SHOW_FORM: 'SHOW_FORM',
};

class DynamicContentForm extends Component {
  constructor(props) {
    super(props);

    this.widthRef = React.createRef();
    this.formRef = React.createRef();

    let formName = '';
    if (props.currentContent?.name) {
      formName = props.currentContent.name;
    } else if (props.newContentName) {
      formName = props.newContentName;
    }

    this.state = {
      dataSources: [],
      dataSource:
        this.props.currentContent && this.props.currentContent.query_obj
          ? this.props.currentContent.query_obj.data_source
          : null,
      description: props.currentContent ? props.currentContent.description : '',
      dynamic_content_method:
        props.currentContent && props.currentContent.dynamic_content_method
          ? props.currentContent.dynamic_content_method
          : null,
      existingInputs: null,
      isChanged: false,
      isLoading: true,
      name: formName,
      nameError: '',
      queryStringError: '',
      columnNameError: '',
      selectedInputName: null,
      testResult: null,
      itemTags: props.currentContent && props.currentContent.item_tags ? props.currentContent.item_tags : [],
      showRefreshTemplateLoading: false,
      refreshTemplateLoadingMessage: 'Processing...',
      dynamicContentFormState: this.props.currentContent
        ? DynamicContentFormStates.SHOW_FORM
        : DynamicContentFormStates.CHOOSE_METHOD,
      isFullscreen: this.props.isFullscreen,
      multi_field_mapping: this.props.currentContent?.multi_field_mapping || {},
    };

    this.formChange = new FormChange(this._getInitialStateValues());
    // while we wait for all inputs to load, using inputs in current content will handle most cases just fine
    this.state.existingInputs = this.props.inputsInQueryString || {};
  }

  _getInitialStateValues() {
    return {
      dataSource: this.state.dataSource,
      description: this.state.description,
      dynamic_content_type: this.props.dynamicContentType,
      dynamic_content_method: this.state.dynamic_content_method,
      name: this.state.name,
      query: this.props.query,
      itemTags: cloneDeep(this.state.itemTags),
    };
  }

  componentDidMount() {
    API.get(
      '/data_sources/?all=true&lite=true',
      (response) => {
        this.setState({ dataSources: response.data, isLoading: false });
      },
      API.defaultError,
    );

    API.get('/parameters/?all=true', (response) => {
      const updatedExistingInputs = {};
      for (let input of response.data) {
        updatedExistingInputs[input.name] = input;
      }
      this.setState({ existingInputs: updatedExistingInputs, allInputsFetched: true });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const stateWithDynamicContentType = {
      dynamic_content_type: this.props.dynamicContentType,
      query: this.props.query,
      ...this.state,
    };
    const isChanged = this.formChange.hasChanged(stateWithDynamicContentType);

    if (isChanged !== this.state.isChanged) {
      this.setState({ isChanged });
      this.props.toggleIsChanged(isChanged);
    }
    if (this.state.isChanged) {
      window.onbeforeunload = () => true;
      if (!prevState.isChanged) {
        this.props.toggleIsChanged();
      } else if (this.props.ui.isChanged && !this.state.isChanged) {
        this.props.toggleIsChanged(false);
      }
    } else {
      window.onbeforeunload = undefined;
    }

    // If we get the fully loaded inputsInQueryString before the all-parameters-fetch, use that for "existingInputs".
    if (
      this.props.inputsInQueryString &&
      !isEqual(prevProps.inputsInQueryString, this.props.inputsInQueryString) &&
      !this.state.allInputsFetched
    ) {
      this.setState({ existingInputs: this.props.inputsInQueryString });
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = undefined;
    this.setState({ isChanged: false });
    this.props.toggleIsChanged(false);
  }

  render() {
    const helpText =
      this.props.dynamicContentType === Constants.DynamicContentTypes.SENDER &&
      this.state.dynamic_content_method === Constants.DynamicContentMethods.QUERY ? (
        'Your Sender dynamic content should return three fields: from_name, from_email, and reply_email.'
      ) : this.props.dynamicContentType === Constants.DynamicContentTypes.RECIPIENTS &&
        this.state.dynamic_content_method === Constants.DynamicContentMethods.QUERY ? (
        <span>
          Recipients Dynamic Content should return data as a single column, with each email address represented as a new
          row.{' '}
          <a
            href="https://help.matik.io/hc/en-us/articles/19331802474779-Creating-Matik-Mail-Templates"
            target="_blank"
            rel="noreferrer"
            className="how-to-text link text-grey-600"
          >
            Learn more.
          </a>
        </span>
      ) : (
        ''
      );
    let accesses;
    let isReadOnly = false;
    if (this.props.currentContent) {
      accesses = new AccessManager(this.props.currentContent.id, this.props.accessesByItemId, this.context.user);
      isReadOnly = !accesses.can('edit');
    }

    const currentDataSource = this.state.dataSource;

    let fullFormBody = '';
    if (this.state.dynamicContentFormState === DynamicContentFormStates.CHOOSE_METHOD) {
      fullFormBody = (
        <div className="pb4xl">
          <ContentTypeForm onContentTypeSelect={this.onContentTypeSelect} />
        </div>
      );
    } else {
      let formBody = '';
      if (this.props.dynamicContentType === Constants.DynamicContentTypes.CONDITIONAL) {
        formBody = (
          <ConditionalContentForm
            dynamicContentArr={this.props.dynamicContentArr}
            selectedInputName={this.state.selectedInputName}
            inputsInQueryString={this.props.inputsInQueryString}
            toggleCurrentInput={this.toggleCurrentInput}
            testResult={this.state.testResult}
            formRef={this.formRef.current}
          />
        );
      } else if (this.state.dynamic_content_method === Constants.DynamicContentMethods.STATIC) {
        formBody = (
          <MatikContentForm
            selectedInputName={this.state.selectedInputName}
            inputsInQueryString={this.props.inputsInQueryString}
            onOutputTypeSelect={this.onOutputTypeSelect}
            entityId={this.props.currentContent ? this.props.currentContent.id : 'new'}
            entityType="dynamic_content"
            formRef={this.formRef.current}
          />
        );
      } else if (this.state.dynamic_content_method === Constants.DynamicContentMethods.INSIGHTS) {
        const insightsBody = <MatikAutoInsightsForm />;
        formBody = (
          <Tile kind="ancestor">
            <Tile kind="parent" vertical>
              <DataSourceFormWrapper
                formBody={insightsBody}
                onMethodChange={this.onMethodChange}
                onOutputTypeSelect={this.onOutputTypeSelect}
                currentDataSource={currentDataSource}
                testResult={this.state.testResult}
              />
            </Tile>
          </Tile>
        );
      } else {
        formBody = (
          <InnerDynamicContentForm
            allDynamicContent={this.props.dynamicContentArr}
            currentDataSource={currentDataSource}
            dynamicContentMethod={this.state.dynamic_content_method}
            entityId={this.props.entityId}
            entityType="dynamic_content"
            formRef={this.formRef.current}
            helpText={helpText}
            isLoading={this.state.isLoading}
            onDataSourceSelect={this.onDataSourceSelect}
            onMethodChange={this.onMethodChange}
            onOutputTypeSelect={this.onOutputTypeSelect}
            inputsInQueryString={this.props.inputsInQueryString}
            columnNameError={this.state.columnNameError}
            testResult={this.state.testResult}
            updateTestResult={(testResult) => this.setState({ testResult })}
            validateFormData={this.validateFormData}
            validationErrors={this.state.validationErrors}
          />
        );
      }
      fullFormBody = (
        <>
          <DynamicContentFormMetadata
            description={this.state.description}
            itemTags={this.state.itemTags}
            isReadOnly={isReadOnly}
            name={this.state.name}
            nameError={this.state.nameError}
            onChange={this.onChange}
            updateEditor={this.updateEditor}
            updateItemTags={this.updateItemTags}
            formRef={this.formRef.current}
            contentOwner={this.props.currentContent?.user}
            query={this.props.currentContent?.query_obj}
          />
          <div className="pb4xl">{formBody}</div>
        </>
      );
    }

    const oldName = this.state.name;

    let contentType = '';
    if (this.state.dynamic_content_method === 'static') {
      contentType = 'static';
    } else if (this.props.dynamicContentType === 'conditional') {
      contentType = 'conditional';
    } else if (this.state.dynamic_content_method === 'insights') {
      contentType = 'insights';
    } else if (this.props.dynamicContentType) {
      contentType = 'dynamic';
    }

    let className = 'dynamic-content-form';
    if (this.props.isFullscreen) {
      if (this.props.ui.googleSheetDrawer) {
        className = 'dynamic-content-form-with-sheet';
      } else {
        className = 'dynamic-content-form-fullscreen';
      }
    }

    const dynamicContentContext = {
      currentDynamicContent: this.props.currentContent,
      dynamicContentType: this.props.dynamicContentType,
      isReadOnly: isReadOnly,
      currentDataSource: currentDataSource,
      existingInputs: this.state.existingInputs,
      query: this.props.query,
      queryStringError: this.state.queryStringError,
      onQueryStringUpdate: this.onQueryStringUpdate,
      onQueryObjectUpdate: (queryObj) => this.onQueryStringUpdate(JSON.stringify(queryObj)),
      multiFieldMapping: this.state.multi_field_mapping,
      onSenderFieldUpdate: this.onSenderFieldUpdate,
    };

    const connectedItems = this.props.currentContent
      ? {
          [Constants.ItemTypes.INPUT]: { items: Object.values(this.props.inputsInQueryString || {}) },
          [Constants.ItemTypes.TEMPLATE]: {
            items: this.props.templates,
            count: this.props.allTemplatesCount ?? 0,
            restricted_count: this.props.restrictedTemplatesCount ?? 0,
          },
        }
      : {};

    if (this.props.isFetching) {
      return <FormSkeletonLoader />;
    } else {
      return (
        <React.Fragment>
          <form onSubmit={this.handleFormSubmit} className={className}>
            <div
              className={`box dc-form-main is-shadowless ${!this.props.currentContent ? 'is-new' : ''}`}
              ref={this.formRef}
            >
              {!this.props.isFullscreen && (
                <FormHeader
                  accesses={accesses}
                  clearContentType={this.clearContentType}
                  contentType={contentType}
                  currentContent={this.props.currentContent}
                  id={this.props.entityId}
                  inputsInQueryString={this.props.inputsInQueryString}
                  connectedItems={connectedItems}
                  isChanged={this.state.isChanged}
                  isNew={!this.props.currentContent}
                  isReadOnly={isReadOnly}
                  linkDisabled={this.props.headerLinkDisabled}
                  name={this.state.name.length > 0 ? this.state.name : 'New Content'}
                  onClose={this.props.onClose}
                  onCloseDataTip={this.props.onCloseDataTip}
                  onContentTypeSelect={this.onContentTypeSelect}
                  onDelete={this.onDelete}
                  openModal={this.props.openModal}
                  formType={Constants.ItemTypes.DYNAMIC_CONTENT}
                  showDelete={!!this.props.currentContent}
                  showClickableCloseIcon={true}
                  url={`/dynamic_content/${this.props.currentContent?.id}`}
                />
              )}
              <DynamicContentContext.Provider value={dynamicContentContext}>
                {fullFormBody}
              </DynamicContentContext.Provider>
              <InputModal
                dataSources={this.filterDataSourcesForInput(this.state.dataSources)}
                onClose={this.onModalClose}
                onInputAdd={this.onInputAdd}
                onInputUpdate={this.onInputUpdate}
                existingInputs={this.props.inputsInQueryString}
              />
              <InputFormSidepane
                dataSources={this.filterDataSourcesForInput(this.state.dataSources)}
                onInputUpdated={this.onInputUpdate}
                onInputAdded={this.onInputAdd}
              />
              {this.state.dynamicContentFormState !== DynamicContentFormStates.CHOOSE_METHOD && (
                <FixedFormButtons
                  buttonWidth={this.props.buttonWidth}
                  database={this.state.database}
                  dataSource={currentDataSource}
                  dataSources={this.state.dataSources}
                  dynamicContentById={this.props.dynamicContentById}
                  dynamicContentType={this.props.dynamicContentType}
                  entityId={this.props.entityId}
                  entityType={this.props.entityType}
                  showCancel={this.props.showCancel}
                  inputsInQueryString={this.props.inputsInQueryString}
                  isChanged={this.state.isChanged}
                  isLoading={this.state.isLoading || this.props.isAllDynamicContentLoading}
                  loadDynamicContentFromNames={this.props.loadDynamicContentFromNames}
                  location={this.props.location}
                  method={this.state.dynamic_content_method}
                  onClose={this.props.onClose}
                  query={this.props.query}
                  saveButtonText={this.props.saveButtonText}
                  schema={this.state.schema}
                  submitForm={this.handleFormSubmit}
                  updateTestResult={(testResult) => this.setState({ testResult })}
                  validateFormData={this.validateFormData}
                />
              )}
              <Prompt
                when={this.state.isChanged}
                message="Are you sure you want to navigate away? There are unsaved changes."
              />
            </div>
          </form>
          <CopyModal
            itemType={Constants.ItemTypes.DYNAMIC_CONTENT}
            oldName={oldName}
            onCopySubmit={this.onCopySubmit}
          />
          <PageLoader
            title={this.state.refreshTemplateLoadingMessage}
            isActive={this.state.showRefreshTemplateLoading}
          />
        </React.Fragment>
      );
    }
  }

  buildDescriptionFromBlocks = (description) => {
    let descriptionText = '';
    description.blocks.forEach((block, idx) => {
      if (idx === 0) {
        descriptionText += block.text;
      } else {
        descriptionText += '\n' + block.text;
      }
    });
    return descriptionText;
  };

  updateEditor = (editorData) => {
    let description = JSON.parse(editorData);
    const descriptionText = this.buildDescriptionFromBlocks(description);
    const newDescription = descriptionText.length ? editorData : '';
    this.setState({ description: newDescription });
  };

  updateItemTags = (newTags) => {
    this.setState({ itemTags: newTags.itemTags });
  };

  filterDataSourcesForInput(dataSources) {
    // For now, we filter out any virtual data sources.
    return dataSources.filter((dataSource) => dataSource.id > 0);
  }

  onModalClose = () => {
    this.toggleCurrentInput(this.state.selectedInputName);
    this.props.closeModal();
  };

  /* This function validates *ALL* the fields of the dynamic content form. The success variable
     is defaulted to true initially. In case of any validation fails, the success variable is
   changed to false and returned. */
  validateFormData = (requiresDataSource, requiresName, query) => {
    query = query ? query : this.props.query;
    let success = true;

    if (requiresName && !this.state.name) {
      const formHead = document.querySelector('.dynamic-content-form-with-sheet, .dynamic-content-form');
      formHead?.scrollIntoView({ behavior: 'smooth' });
      this.setState({ nameError: 'Name is required' });
      success = false;
    }
    if (requiresDataSource && !this.state.dataSource) {
      MAlert('Data source is required', 'Error', 'error');
      success = false;
    }
    if (
      !requiresDataSource &&
      (!this.props.inputsInQueryString || Object.values(this.props.inputsInQueryString).length === 0)
    ) {
      MAlert('An input is required to create dynamic content', 'Error', 'error');
      success = false;
    }

    if (requiresDataSource && !query) {
      this.setState({ queryStringError: 'Query string required' });
      success = false;
    }

    if (this.state.dataSource && this.state.dataSource.type === Constants.DATA_SOURCE_TYPES.tableau) {
      for (let inputType in this.props.inputsInQueryString) {
        if (this.props.inputsInQueryString[inputType].type === 'date_range') {
          MAlert('Tableau API does not support Date Ranges. Please select a new input type', 'Error', 'error');
          success = false;
        }
      }
    }
    const senderRequiredFields = ['from_name', 'from_email', 'reply_email'];
    const missingFields = [];
    if (this.props.dynamicContentType === Constants.DynamicContentTypes.SENDER) {
      if (this.state.dynamic_content_method === Constants.DynamicContentMethods.QUERY) {
        senderRequiredFields.forEach((field) => {
          if (!query.includes(field)) {
            missingFields.push(field);
          }
        });
      } else if (
        this.state.dataSource.type !== Constants.DATA_SOURCE_TYPES.google_sheet &&
        this.state.dataSource &&
        this.state.dataSource.type !== Constants.DATA_SOURCE_TYPES.excel
      ) {
        senderRequiredFields.forEach((field) => {
          if (!Object.keys(this.state.multi_field_mapping).includes(field)) {
            missingFields.push(field);
          }
        });
      }
      if (missingFields.length) {
        this.setState({
          queryStringError: `Your Sender dynamic content is missing required fields: ${missingFields.join(', ')}.`,
        });
        success = false;
      }
    }

    /* Sample example of the query string : {"output":{"columns":"A","cells":"Sheet1!A1:B2"}}
        query_obj converts the query of type string to a JSON object. Hence, "columns" and "cells" values
        can be individually accessed and validated.*/
    if (
      (this.state.dataSource && this.state.dataSource.type === Constants.DATA_SOURCE_TYPES.google_sheet) ||
      (this.state.dataSource && this.state.dataSource.type === Constants.DATA_SOURCE_TYPES.excel)
    ) {
      if (query.length === 0 || !query) {
        this.setState({ queryStringError: 'Cells in sheet are required' });
        success = false;
      } else {
        var query_obj = JSON.parse(query);
        if (!query_obj.output || !query_obj.output.cells) {
          this.setState({ queryStringError: 'Cells in sheet are required' });
          success = false;
        } else if (
          this.props.dynamicContentType === Constants.DynamicContentTypes.RECIPIENTS &&
          !query_obj.output['columns']
        ) {
          this.setState({ columnNameError: 'Column name is required for recipients dynamic content' });
          success = false;
        }
      }
    }

    if (this.state.dataSource?.type === Constants.DATA_SOURCE_TYPES.power_bi) {
      const parsedQuery = JSON.parse(query);

      const validationResult =
        parsedQuery?.type === 'semantic_model'
          ? validatePowerBISemanticModelQuery(parsedQuery)
          : validatePowerBIReportQuery(parsedQuery);

      this.setState({ validationErrors: validationResult.errors });

      if (!validationResult.isValid) {
        success = false;
      }
    }

    return success;
  };

  onContentTypeSelect = (contentType) => {
    let newDynamicContentType;
    if (contentType === Constants.ContentType.STATIC) {
      newDynamicContentType = this.props.defaultContentType || Constants.DynamicContentTypes.TEXT;
      this.props.setDynamicContentType(newDynamicContentType);
      this.setState({
        dynamic_content_method: Constants.DynamicContentMethods.STATIC,
        dataSource: { id: Constants.DATA_SOURCE_MATIK_CONTENT_ID },
        dynamicContentFormState: DynamicContentFormStates.SHOW_FORM,
      });
    } else if (contentType === Constants.ContentType.CONDITIONAL) {
      if (teams.isTeamsUser(this.context.user.enterprise.plan_id)) {
        const planName = Constants.MATIK_TIERS[this.context.user.enterprise.plan_id].display_name;
        const noConditionalContentHtml = ReactDOMServer.renderToStaticMarkup(
          <UpsellGenericModal
            featureHeader={`Conditional Content with Matik ${Constants.MATIK_TIERS.matik_enterprise.display_name}`}
            featureDescription={`Conditional Content is not available for the ${planName} plan. Please upgrade to add content conditions.`}
          />,
        );
        API.track('enterprise_upsell_shown', { from: 'no_conditional_content' });
        MProductUpsell(`${noConditionalContentHtml}`, false, (result) => {
          if (!result.dismiss) {
            API.track('discover_matik_enterprise_click', { from: 'no_conditional_content' });
            const demoUrl = teams.buildRequestDemoUrl(
              this.context.user,
              'conditional_content',
              this.context.user.enterprise.trial_days_remaining,
            );
            window.open(demoUrl, '_blank');
          }
        });
      } else {
        this.setState({
          dynamic_content_method: Constants.DynamicContentMethods.CONSUMER_INPUT,
          dynamicContentFormState: DynamicContentFormStates.SHOW_FORM,
        });
        this.props.setDynamicContentType(Constants.ContentType.CONDITIONAL);
      }
    } else if (contentType === Constants.ContentType.DYNAMIC) {
      newDynamicContentType = this.props.defaultContentType || Constants.DynamicContentTypes.TEXT;
      this.setState({
        dynamic_content_method: Constants.DynamicContentMethods.CONSUMER_INPUT,
        dynamicContentFormState: DynamicContentFormStates.SHOW_FORM,
      });
      this.props.setDynamicContentType(newDynamicContentType);
    } else if (contentType === Constants.ContentType.INSIGHTS) {
      this.setState({
        dynamic_content_method: Constants.DynamicContentMethods.INSIGHTS,
        dynamicContentFormState: DynamicContentFormStates.SHOW_FORM,
        dataSource: find(this.state.dataSources, (d) => d.id === -4),
      });
      this.props.setDynamicContentType(Constants.DynamicContentTypes.TEXT);
    }
  };

  onQueryStringUpdate = (value, filters = [], returnFields = {}) => {
    // Convert Salesforce query-based values to JSON string
    if (this.state?.dataSource?.type === 'salesforce' && !utils.isValidJSON(value)) {
      value = JSON.stringify({
        filters: filters,
        soql_string: value,
        source: 'objects',
        returnFieldsTypes: returnFields.returnFieldsTypes,
        fields: returnFields.returnFields,
      });
    }
    if (value !== this.props.query) {
      this.props.setQuery(value);
    }

    return Promise.resolve();
  };

  onSenderFieldUpdate = (senderFieldName, returnFieldName) => {
    const multi_field_mapping_obj = { ...this.state.multi_field_mapping };
    if (!returnFieldName) {
      delete multi_field_mapping_obj[senderFieldName];
    } else {
      multi_field_mapping_obj[senderFieldName] = returnFieldName;
    }
    this.setState({ multi_field_mapping: multi_field_mapping_obj });
  };

  toggleCurrentInput = (inputName) => {
    const updatedInputs = this.props.inputsInQueryString;
    let updatedSelectedInput = this.state.selectedInputName;
    if (inputName === updatedSelectedInput) {
      updatedSelectedInput = '';
    } else {
      if (updatedInputs && updatedInputs[inputName]) {
        updatedSelectedInput = inputName;
      }
    }
    if (!this.props.activeModal) {
      this.props.openModal('inputModal', updatedInputs[updatedSelectedInput]);
    } else {
      this.props.closeModal();
    }
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    // Loading the form is initially defaulted to true.
    this.setState({ isLoading: true });
    if (this.validateFormData(!!this.state.dataSource, true)) {
      const data = {
        name: this.state.name,
        description: this.state.description,
        params: inputs.inputsInQueryWithoutNested(
          this.props.query,
          this.props.inputsInQueryString || {},
          this.props.dynamicContentType,
        ),
        dynamic_content_type: this.props.dynamicContentType,
        dynamic_content_method: this.state.dynamic_content_method,
        query: {
          query_string: this.props.query,
          data_source: this.state.dataSource ? { id: this.state.dataSource.id } : null,
          database: this.state.database,
          schema: this.state.schema,
        },
        item_tags: this.state.itemTags,
        update_templates: false,
        multi_field_mapping: this.state.multi_field_mapping,
      };
      if (this.props.currentContent) {
        if (this.props.currentContent.name !== data.name && this.props.allTemplatesCount > 0) {
          MConfirm(
            'Name Update',
            'Do you want to update the name for this piece of dynamic content across all the templates it is associated with?',
            'warning',
            (confirmed) => {
              if (confirmed) {
                data.update_templates = true;
                this.handleUpdateContent(data);
              } else {
                this.handleUpdateContent(data);
              }
            },
            'Yes',
            'No',
          );
        } else {
          this.handleUpdateContent(data);
        }
      } else {
        this.props
          .create(data)
          .then((response) => {
            API.track('dynamic_content_add', {
              name: this.state.name,
              dynamicContentType: this.props.dynamicContentType,
              dynamic_content_method: this.state.dynamic_content_method,
              data_source: this.state.dataSource
                ? { name: this.state.dataSource.name, type: this.state.dataSource.type }
                : null,
            });
            this.setState({
              isLoading: false,
              isChanged: false,
              name: '',
              description: '',
              database: '',
              dataSource: null,
              dynamic_content_method: Constants.DynamicContentMethods.CONSUMER_INPUT,
              schema: '',
              testResult: null,
              inputsInQueryString: null,
              selectedInputName: '',
              queryStringError: '',
              columnNameError: '',
              validationErrors: {},
            });
            this.props.setDynamicContentType(null);
            this.props.setQuery('');
            this.formChange.updateInitialValues(this._getInitialStateValues());
            this.props.toggleIsChanged(false);
            this.props.invalidateAccesses();
            this.props.onContentAdd?.(response.data.new_entity.id);
            this.props.onContentUpdate(data);
            if (this.props.onSave) {
              this.props.onSave(response.data.new_entity);
            }
          })
          .catch(this.submitError);
      }
    } else {
      /* If validateFormData() returns false i.e the form field validation fails, then,
         we set the loading state back to false.*/
      this.setState({ isLoading: false });
    }
  };

  refreshTemplateLoading = (info) => {
    this.setState({
      refreshTemplateLoadingMessage: info,
    });
  };

  handleUpdateContent = (data) => {
    const onUpdate = () => {
      this.setState({
        isLoading: false,
        testResult: null,
        showRefreshTemplateLoading: false,
        isChanged: false,
        queryStringError: '',
        columnNameError: '',
      });
      API.track('dynamic_content_update');
      this.formChange.updateInitialValues(this._getInitialStateValues());
      this.setState({ isChanged: this.formChange.hasChanged(this.state) });
      this.props.onContentUpdate(data);
    };
    if (data.update_templates) {
      this.setState({ showRefreshTemplateLoading: true });
      return this.props
        .updateWithTemplates(this.props.currentContent.id, data, this.refreshTemplateLoading)
        .then(onUpdate)
        .catch(this.submitError);
    } else {
      return this.props.update(this.props.currentContent.id, data).then(onUpdate).catch(this.submitError);
    }
  };

  onCopySubmit = (e, newName) => {
    e.preventDefault();
    if (this.validateFormData(!!this.state.dataSource, true)) {
      const data = {
        name: newName,
        description: this.state.description,
        params: inputs.inputsInQueryWithoutNested(
          this.props.query,
          this.props.inputsInQueryString || {},
          this.props.dynamicContentType,
        ),
        dynamic_content_type: this.props.dynamicContentType,
        dynamic_content_method: this.state.dynamic_content_method,
        query: {
          query_string: this.props.query,
          data_source: { id: this.state.dataSource.id },
          database: this.state.database,
          schema: this.state.schema,
        },
      };
      this.props
        .create(data)
        .then((response) => {
          this.formChange.updateInitialValues(this._getInitialStateValues());
          this.props.closeModal();
          this.props.onContentAdd?.(response.data.new_entity.id);
          API.track('dynamic_content_copy');
          this.setState({ dataSource: null });
          this.props.invalidateAccesses();
        })
        .catch(this.submitError);
    }
  };

  onDelete = (e) => {
    e.preventDefault();
    MConfirm('Delete', 'Are you sure you want to delete this dynamic content?', 'warning', (confirmed) => {
      if (confirmed) {
        this.props.closeSidepane();
        const dynamicContentId = this.props.currentContent.id;
        this.props
          .deleteContent(dynamicContentId)
          .then(() => {
            API.track('dynamic_content_delete');
            this.props.invalidateAccesses();
            this.props.onContentUpdate();
            this.props.onContentDeleted?.(dynamicContentId);
          })
          .catch(this.submitError);
      }
    });
  };

  submitError = (err) => {
    this.setState({ isLoading: false, testResult: null });
    API.defaultError(err);
  };

  /**
   * Update the fieldValue based on certain conditions
   * @param {string} fieldId Identifier of the field
   * @param {string} fieldValue Value of field to be updated and returned
   * @returns {string}
   */
  formatFormField = (fieldId, fieldValue) => {
    let result = fieldValue;

    if (fieldId === 'dynamic-content-name') {
      result = fieldValue.replace(/[ .:|{}(),]/g, '_');
    }

    return result;
  };

  onChange = (e) => {
    e.preventDefault();

    let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    value = this.formatFormField(e.target.id, value);

    const targetErrorState = [e.target.name] + 'Error';

    this.setState({
      [e.target.name]: value,
      [targetErrorState]: '',
      submitError: '',
    });
  };

  onOutputTypeSelect = (value) => {
    // special cases for static images to avoid raw image data showing in the query editor
    const switchingFromStaticImage =
      value !== Constants.DynamicContentTypes.IMAGE &&
      this.state.dynamic_content_method === Constants.DynamicContentMethods.STATIC &&
      this.props.dynamicContentType === Constants.DynamicContentTypes.IMAGE;

    const switchingBackToStaticImage =
      value === Constants.DynamicContentTypes.IMAGE &&
      this.state.dynamic_content_method === Constants.DynamicContentMethods.STATIC &&
      this.props.dynamicContentType !== Constants.DynamicContentTypes.IMAGE &&
      this.props.currentContent?.dynamic_content_type === Constants.DynamicContentTypes.IMAGE;

    if (switchingFromStaticImage) {
      this.props.setQuery('');
    }

    if (switchingBackToStaticImage) {
      const query =
        this.props.currentContent && this.props.currentContent.query_obj
          ? this.props.currentContent.query_obj.query_string
          : '';
      this.props.setQuery(query);
    }

    this.props.setDynamicContentType(value);
  };

  onMethodChange = (value) => {
    const updateMethod = () => {
      this.setState({
        dynamic_content_method: value,
      });
      this.props.setQuery('');
    };
    if (this.props.query.length > 0) {
      MConfirm(
        'Unsaved Changes',
        'Are you sure you want to navigate away? Unsaved changes will be lost.',
        'warning',
        (confirmed) => {
          if (confirmed) {
            updateMethod();
          }
        },
      );
    } else {
      if (value !== this.state.dynamic_content_method) {
        updateMethod();
      }
    }
  };

  onDataSourceSelect = (dataSource) => {
    // reset method
    const supportedMethods = Object.values(Constants.DynamicContentMethods).filter((method) =>
      Constants.DATA_SOURCE_TYPES_FOR_METHOD[method].includes(dataSource.type),
    );
    if (!supportedMethods.includes(this.state.dynamic_content_method)) {
      this.setState({
        dynamic_content_method: supportedMethods[0],
      });
      this.props.setQuery('');
    }
    if (
      Constants.DYNAMIC_CONTENT_TYPES_BY_DATA_SOURCE[dataSource.type] &&
      !Constants.DYNAMIC_CONTENT_TYPES_BY_DATA_SOURCE[dataSource.type].includes(this.props.dynamicContentType)
    ) {
      this.props.setDynamicContentType(Constants.DYNAMIC_CONTENT_TYPES_BY_DATA_SOURCE[dataSource.type][0]);
    }

    this.setState({ testResult: null, dataSource: dataSource });
    this.props.setQuery('');
  };

  onInputUpdate = (updatedInput) => {
    const updatedExistingInputs = this.state.existingInputs;
    if (updatedExistingInputs && updatedExistingInputs[updatedInput.name]) {
      updatedExistingInputs[updatedInput.name] = updatedInput;
    }
    this.props.invalidateInputs();
    // Deal with name change special case
    this.setState({
      existingInputs: updatedExistingInputs,
    });
  };

  onInputAdd = (addedInput) => {
    const updatedExistingInputs = this.state.existingInputs;

    if (updatedExistingInputs) {
      updatedExistingInputs[addedInput.name] = addedInput;
    }
    this.setState({
      existingInputs: updatedExistingInputs,
    });
    this.props.invalidateInputs();
  };

  getWidth = () => {
    if (this.widthRef.current) {
      return this.widthRef.current.clientWidth;
    } else {
      return 0;
    }
  };

  clearContentType = () => {
    this.setState({
      dynamic_content_method: null,
      dataSource: null,
    });
    this.props.setQuery('');
    this.props.setDynamicContentType(null);
  };
}

DynamicContentForm.propTypes = {
  accessesByItemId: PropTypes.object,
  allTemplatesCount: PropTypes.number,
  restrictedTemplatesCount: PropTypes.number,
  currentContent: PropTypes.object,
  dynamicContentArr: PropTypes.array,
  dynamicContentById: PropTypes.object,
  isAllDynamicContentLoading: PropTypes.bool,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entityType: PropTypes.string,
  invalidateAccesses: PropTypes.func,
  loadDynamicContentFromNames: PropTypes.func,
  buttonWidth: PropTypes.string,
  onSave: PropTypes.func,
  saveButtonText: PropTypes.string,
  templates: PropTypes.array,
  location: PropTypes.object,
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  toggleIsChanged: PropTypes.func,
  onContentUpdate: PropTypes.func,
  isFetching: PropTypes.bool,
  activeModal: PropTypes.object,
  ui: PropTypes.object,
  onContentAdd: PropTypes.func,
  onContentDeleted: PropTypes.func,
  onClose: PropTypes.func,
  onCloseDataTip: PropTypes.string,
  headerLinkDisabled: PropTypes.bool,
  showCancel: PropTypes.bool,
  closeSidepane: PropTypes.func,
  defaultContentType: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  newContentName: PropTypes.string,
  create: PropTypes.func,
  update: PropTypes.func,
  updateWithTemplates: PropTypes.func,
  deleteContent: PropTypes.func,
  dynamicContentType: PropTypes.string,
  setDynamicContentType: PropTypes.func,
  query: PropTypes.string,
  inputsInQueryString: PropTypes.object,
  setQuery: PropTypes.func,
  invalidateInputs: PropTypes.func,
};

DynamicContentForm.contextType = UserContext;

function mapStateToProps(state, ownProps) {
  return {
    ...mapUiStateToProps(state, ownProps),
  };
}

function mapDispatchToProps(state, ownProps) {
  return {
    ...notificationDispatchers.mapDispatchToProps(state, ownProps),
    ...mapUiDispatchToProps(state, ownProps),
  };
}

const DynamicContentFormWrapper = (props) => {
  const [query, setQuery] = useState(props.currentContent?.query_obj?.query_string ?? '');
  const [dynamicContentType, setDynamicContentType] = useState(props.currentContent?.dynamic_content_type ?? null);
  const { data: accessesByItemId, invalidate: invalidateContentAccesses } = useAccesses('dynamic_content');
  const { invalidateAll: invalidateAllContent } = useTemplateContent();
  const { invalidate: invalidateTemplate } = useTemplate();
  const { create, update, updateWithTemplates, deleteContent } = useDynamicContentMutator();
  const { templates, allTemplatesCount, restrictedTemplatesCount } = useDynamicContentTemplates(props.entityId);
  const handleContentUpdate = (data) => {
    invalidateAllContent();
    if (data?.update_templates && props.templates?.length > 0) {
      props.templates.forEach((template) => invalidateTemplate(template.id));
    }
  };
  const { isLoading: allDynamicContentLoading, dynamicContentById } = useAllDynamicContentById();
  const { inputsByName: queryInputsByName, invalidate } = useQueryInputList(query, dynamicContentType, {});
  return (
    <DynamicContentForm
      {...props}
      onContentUpdate={handleContentUpdate}
      accessesByItemId={accessesByItemId}
      invalidateAccesses={invalidateContentAccesses}
      templates={templates}
      allTemplatesCount={allTemplatesCount}
      restrictedTemplatesCount={restrictedTemplatesCount}
      create={create}
      update={update}
      updateWithTemplates={updateWithTemplates}
      deleteContent={deleteContent}
      dynamicContentById={dynamicContentById}
      isAllDynamicContentLoading={allDynamicContentLoading}
      dynamicContentArr={Object.values(dynamicContentById || {})}
      dynamicContentType={dynamicContentType}
      setDynamicContentType={setDynamicContentType}
      // Query info
      inputsInQueryString={queryInputsByName}
      query={query}
      setQuery={setQuery}
      invalidateInputs={invalidate}
    />
  );
};
DynamicContentFormWrapper.propTypes = {
  entityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentContent: PropTypes.object,
  inputValues: PropTypes.object,
  // from mapStateToProps
  templates: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DynamicContentFormWrapper));
