import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { isEmpty, isEqual } from 'lodash';
import Pluralize from 'pluralize';
import Constants from 'components/Constants';
import utils from 'lib/utils';
import InputsList from 'lib/inputsList';
import { isLoopable, getLoopedInfo, hasSlidesWithLoops, useIsConditionalContentInput } from 'lib/looping';
import Button from 'components/lib/Button';
import DropdownMenu from 'components/lib/DropdownMenu';
import DropdownMenuItem from 'components/lib/DropdownMenuItem';
import ListItem from 'components/lib/ListItem';
import { MAlert, MConfirm } from 'components/shared/Alerts';
import WithInstantSearch from 'components/shared/search/WithInstantSearch';
import SearchBoxAndFilters from 'components/shared/search/SearchBoxAndFilters';
import ComponentWithHits from 'components/shared/search/ComponentWithHits';
import InputIcon from 'components/producer/templates/InputIcon';
import InputDisplayName from 'components/shared/templates/InputDisplayName';
import IconPill from '../../lib/IconPill';
import Icon from 'components/lib/Icon';
import { useTemplateMutator } from 'lib/hooks/useTemplate';
import AvatarGroup from 'components/lib/AvatarGroup';
import { useAccessMutator, useGenerationIssues } from 'lib/hooks/useAccess';
import { useFlags } from 'launchdarkly-react-client-sdk';

const LoopButton = ({ color, disabled, tooltip, onClick }) => {
  let tip = '';
  if (tooltip) {
    tip = tooltip;
  } else if (color) {
    tip = 'This input will be looped on, with customized content being generated for each of its provided values.';
  }

  return (
    <div
      disabled={disabled}
      data-tooltip-id="matik-tooltip"
      data-tooltip-content={tip}
      role={onClick ? 'button' : undefined}
      className={`${onClick ? 'button' : ''} small square pan man is-borderless is-circle ${
        color ? `color-white background-${color}` : 'is-dark-gray-hover'
      }`}
      onClick={!disabled && onClick ? onClick : undefined}
    >
      <IconPill iconName="loop" color={color} size="m" theme="circle" />
    </div>
  );
};
LoopButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
  onClick: PropTypes.func,
};
LoopButton.displayName = 'TemplateInputsList.LoopButton';

const TemplateInput = ({
  input,
  template,
  looped,
  dragAndDrop,
  isDragDisabled,
  permissionIssues,
  onHighlightSlides,
  onUnhighlightSlides,
  onClick,
  onLoopClick,
}) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const { update: updateAccesses } = useAccessMutator();
  const [isConditionalContent] = useIsConditionalContentInput(input.id, template?.id);

  const isInputMapped =
    input.input_mapping &&
    !isEmpty(input.input_mapping) &&
    !isEqual(Object.keys(input.input_mapping)[0], Object.values(input.input_mapping)[0]);

  const hasNested = input.nested_parameters && Object.keys(input.nested_parameters).length > 0;

  const onDropdownOpen = (e) => {
    e.stopPropagation();
    setIsDropdownActive(!isDropdownActive);
  };

  const handleAdd = () => {
    const accessors = permissionIssues.map((accessor) => ({
      accessor_type: accessor.type,
      accessor_id: accessor.id,
    }));
    MConfirm(
      'Updating access to relevant assets',
      'Granting users Read access to this input will also make them eligible for Read access to the associated data source.',
      'warning',
      (confirmed) => {
        if (confirmed) {
          updateAccesses(
            Constants.ItemTypes.DATA_SOURCE,
            [input.query_obj.data_source.id],
            Constants.PERMISSIONS.read.value,
            accessors,
          );
        }
      },
      'Update access',
    );
  };

  const handleLoopClick = () => {
    onLoopClick({
      loop: looped?.loop,
      input: input,
    });
  };

  let cannotLoopBecause = '';
  if (!isLoopable(input)) {
    cannotLoopBecause = 'Looping is not supported for this input type.';
  } else if (hasSlidesWithLoops(input, template)) {
    cannotLoopBecause = 'Slide(s) referencing this input are already included in another loop.';
  } else if (isConditionalContent) {
    cannotLoopBecause = 'Looping on conditional inputs is not supported';
  }

  let grantAccessSubtitle = 'For';
  const templateGroupAccessorsWithoutInputAccess =
    permissionIssues?.filter((accessor) => accessor.type === 'group') ?? [];
  const templateUserAccessorsWithoutInputAccess =
    permissionIssues?.filter((accessor) => accessor.type === 'user') ?? [];
  if (templateGroupAccessorsWithoutInputAccess.length) {
    grantAccessSubtitle += ` ${Pluralize('group', templateGroupAccessorsWithoutInputAccess.length, true)}`;
    if (templateUserAccessorsWithoutInputAccess.length) {
      grantAccessSubtitle += ' and';
    }
  }
  if (templateUserAccessorsWithoutInputAccess.length) {
    grantAccessSubtitle += ` ${Pluralize('user', templateUserAccessorsWithoutInputAccess.length, true)}`;
  }

  return (
    <div
      className="is-flex group"
      onMouseEnter={() => onHighlightSlides?.(input.slideNums)}
      onMouseLeave={() => onUnhighlightSlides?.()}
    >
      <div
        className="mvxs is-full-width"
        ref={dragAndDrop?.innerRef}
        {...dragAndDrop?.draggableProps}
        {...dragAndDrop?.dragHandleProps}
      >
        <div
          className="has-light-gray-border group-hover:has-green-border background-white phm is-flex is-vertical-centered h-14"
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
        >
          <div className="is-flex is-vertical-centered is-full-width gap-small">
            <div
              className="has-cursor-pointer text-overflow-ellipsis is-flex is-vertical-centered gap-3 is-grow"
              role="button"
              onClick={onClick}
            >
              {permissionIssues ? (
                <IconPill iconColor="red-500" iconName="error_circle" size="s" iconTheme="filled" theme="square" />
              ) : (
                <InputIcon input={input} />
              )}
              <div
                title={InputDisplayName.text(input)}
                className="text-overflow-ellipsis is-flex gap-small is-flex-col"
              >
                <div className="text-overflow-ellipsis font-m">
                  <InputDisplayName input={input} />
                </div>
                {permissionIssues ? (
                  <span className="text-red-500">Access issues</span>
                ) : (
                  (isInputMapped || hasNested) && (
                    <div className="font-s">
                      {isInputMapped && (
                        <div className="is-dark-gray text-overflow-ellipsis">
                          Maps to {Object.values(input.input_mapping)[0]}
                        </div>
                      )}
                      {hasNested && (
                        <div className="is-dark-gray text-overflow-ellipsis">
                          Depends on{' '}
                          {utils.convertComponentArrayToEnglish(
                            Object.values(input.nested_parameters).map((i) => (
                              <InputDisplayName key={i.name} input={i} />
                            )),
                          )}
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
            {permissionIssues && (
              <div>
                <AvatarGroup size="s" limit={2} avatars={permissionIssues} />
              </div>
            )}
            <div className="is-flex content-centered">
              {(looped || onLoopClick) && (
                <LoopButton
                  color={looped?.color}
                  onClick={onLoopClick ? handleLoopClick : null}
                  disabled={!!cannotLoopBecause}
                  tooltip={cannotLoopBecause}
                />
              )}
              {!isDragDisabled && !permissionIssues && <Icon name="reorder_dots_vertical" size={20} theme="regular" />}
              {permissionIssues && isMouseOver && (
                <DropdownMenu
                  dropdownTrigger={
                    <Button category="tertiary" size="small" onClick={onDropdownOpen}>
                      <Icon name="three_dots" size={20} theme="regular" color="matik-black" />
                    </Button>
                  }
                  active={isDropdownActive}
                  onClose={() => setIsDropdownActive(false)}
                >
                  <DropdownMenuItem>
                    <ListItem onClick={handleAdd} title="Grant Access" subtitle={grantAccessSubtitle} />
                  </DropdownMenuItem>
                </DropdownMenu>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
TemplateInput.propTypes = {
  input: PropTypes.object.isRequired,
  template: PropTypes.object,
  dragAndDrop: PropTypes.object,
  isDragDisabled: PropTypes.bool,
  looped: PropTypes.shape({
    loop: PropTypes.object.isRequired,
    color: PropTypes.string,
  }),
  permissionIssues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      name: PropTypes.string,
      photoUrl: PropTypes.string,
    }),
  ),
  onHighlightSlides: PropTypes.func,
  onUnhighlightSlides: PropTypes.func,
  onClick: PropTypes.func,
  onLoopClick: PropTypes.func,
};
TemplateInput.displayName = 'TemplateInputsList.TemplateInput';

export const OrderableInputList = ({
  inputsOrder,
  onInputsOrderChange,
  inputsByName,
  onClick,
  onLoopClick,
  onHighlightSlides,
  onUnhighlightSlides,
  isDragDisabled,
  additionalInputs,
  loops,
  template,
  permissionIssuesByInput,
}) => {
  const updateInputOrder = (update, inputs) => {
    if (!update.destination) {
      return;
    }
    const inputName = update.draggableId;
    const startIndex = update.source.index;
    const dropIndex = update.destination.index;
    if (startIndex === dropIndex) {
      return;
    }

    const newInputsOrder = inputsOrder.length > 0 ? [...inputsOrder] : inputs.map((input) => input.name);
    newInputsOrder.splice(startIndex, 1);
    newInputsOrder.splice(dropIndex, 0, inputName);

    const updatedInputList = new InputsList(inputsByName, newInputsOrder);
    const sortedInputs = updatedInputList.getSortedList();
    const inputsOrderWithSort = sortedInputs.map((input) => input.name);
    if (isEqual(inputsOrderWithSort, inputsOrder)) {
      MAlert("Can't set input order. Inputs need to be ordered after dependent inputs", 'Input Order Error', 'error');
      return;
    }
    onInputsOrderChange(inputsOrderWithSort);
  };

  const inputsList = new InputsList(inputsByName, inputsOrder);
  let orderedInputs = inputsList.getSortedList();

  // Add add'l inputs to the end, ignoring any already in the primary list.
  // Drag'n'drop is automatically disabled for add'l inputs.
  let orderedAdditionalInputs = [];
  if (additionalInputs?.inputsByName && Object.keys(additionalInputs.inputsByName).length > 0) {
    const orderedInputIds = orderedInputs.map((input) => input.id);
    const additionalInputsList = new InputsList(additionalInputs.inputsByName, additionalInputs.order);
    orderedAdditionalInputs = additionalInputsList.getSortedList();

    // remove any duplicates in add'l inputs
    orderedAdditionalInputs = orderedAdditionalInputs.filter((input) => !orderedInputIds.includes(input.id));
  }

  if (orderedInputs.length < 2) {
    isDragDisabled = true;
  }

  const getPermissionsIssues = (input) => {
    return permissionIssuesByInput?.[input.id];
  };

  return (
    <DragDropContext
      onDragEnd={(update) => {
        updateInputOrder(update, orderedInputs);
      }}
    >
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {orderedInputs.map((input, index) => (
              <Draggable key={input.id} draggableId={input.name} index={index} isDragDisabled={isDragDisabled}>
                {(provided) => (
                  <TemplateInput
                    input={input}
                    looped={getLoopedInfo(input, loops)}
                    dragAndDrop={provided}
                    onClick={(e) => onClick(e, input)}
                    onLoopClick={onLoopClick ? () => onLoopClick(input) : null}
                    onHighlightSlides={onHighlightSlides}
                    onUnhighlightSlides={onUnhighlightSlides}
                    isDragDisabled={isDragDisabled}
                    template={template}
                    permissionIssues={getPermissionsIssues(input)}
                  />
                )}
              </Draggable>
            ))}
            {orderedAdditionalInputs.map((input, index) => (
              <Draggable key={input.id} draggableId={input.name} index={index} isDragDisabled={true}>
                {(provided) => (
                  <div
                    data-tooltip-content={
                      'This input is part of the attachment. To edit the sort order, navigate to that template.'
                    }
                    data-tooltip-id="matik-tooltip"
                  >
                    <TemplateInput
                      input={input}
                      looped={getLoopedInfo(input, loops)}
                      dragAndDrop={provided}
                      onClick={(e) => onClick(e, input)}
                      onLoopClick={onLoopClick ? () => onLoopClick(input) : null}
                      onHighlightSlides={onHighlightSlides}
                      onUnhighlightSlides={onUnhighlightSlides}
                      isDragDisabled={true}
                      template={template}
                      permissionIssues={getPermissionsIssues(input)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
OrderableInputList.propTypes = {
  inputsOrder: PropTypes.array,
  onInputsOrderChange: PropTypes.func,
  inputsByName: PropTypes.object,
  onHighlightSlides: PropTypes.func,
  onUnhighlightSlides: PropTypes.func,
  onClick: PropTypes.func,
  onLoopClick: PropTypes.func,
  isDragDisabled: PropTypes.bool,
  additionalInputs: PropTypes.shape({
    order: PropTypes.array,
    inputsByName: PropTypes.object,
  }),
  loops: PropTypes.arrayOf(
    PropTypes.shape({
      parameter: PropTypes.number,
    }),
  ),
  template: PropTypes.object,
  permissionIssuesByInput: PropTypes.object,
};
OrderableInputList.displayName = 'TemplateInputsList.OrderableInputList';

const SearchedInputList = ({
  inputsByName,
  inputsOrder,
  onClick,
  onLoopClick,
  loops,
  template,
  permissionIssuesByInput,
}) => {
  const inputsList = new InputsList(inputsByName, inputsOrder);
  return (
    <ComponentWithHits
      inputsByName={inputsList.inputs}
      onClick={onClick}
      onLoopClick={onLoopClick}
      WrappedComponent={SearchedInputs}
      loops={loops}
      template={template}
      permissionIssuesByInput={permissionIssuesByInput}
    />
  );
};
SearchedInputList.propTypes = {
  inputsByName: PropTypes.object,
  inputsOrder: PropTypes.array,
  onClick: PropTypes.func,
  onLoopClick: PropTypes.func,
  loops: PropTypes.arrayOf(
    PropTypes.shape({
      parameter: PropTypes.number,
    }),
  ),
  template: PropTypes.object,
  permissionIssuesByInput: PropTypes.object,
};
SearchedInputList.displayName = 'TemplateInputsList.SearchedInputList';

const SearchedInputs = ({
  entitiesToRender,
  onClick,
  onLoopClick,
  inputsByName,
  loops,
  template,
  permissionIssuesByInput,
}) => {
  return entitiesToRender
    .map((entity, index) => {
      const input = inputsByName[entity.name];
      if (input) {
        return (
          <TemplateInput
            key={index}
            input={input}
            template={template}
            looped={getLoopedInfo(input, loops)}
            onClick={(e) => onClick(e, input)}
            onLoopClick={onLoopClick ? () => onLoopClick(input) : null}
            isDragDisabled
            permissionIssues={permissionIssuesByInput?.[input.id]}
          />
        );
      }
    })
    .filter((i) => !!i);
};

const SearchTemplateInputsList = ({
  currentTemplate,
  inputsInMatchingContent,
  selectedSlides,
  isSearching,
  isDragDisabled,
  handleClick,
  onLoopClick,
  attachedTemplate,
  attachmentInputsInMatchingContent,
  highlightSlides,
  unhighlightSlides,
  searchState,
  searchAttributes,
}) => {
  const templateParamsOrder = currentTemplate.params_order || [];
  const [inputsOrder, setInputsOrder] = useState(templateParamsOrder);
  useEffect(() => {
    setInputsOrder(templateParamsOrder);
  }, [JSON.stringify(templateParamsOrder)]);

  const generationIssues = useGenerationIssues([currentTemplate.id]);
  const { dataSourcePermissions } = useFlags();
  const permissionIssuesByInput = dataSourcePermissions && generationIssues[currentTemplate.id]?.['parameter'];

  const { update } = useTemplateMutator();

  const handleInputsOrderChange = (inputsOrderWithSort) => {
    setInputsOrder(inputsOrderWithSort);

    update({ id: currentTemplate.id, params_order: inputsOrderWithSort }, null, false)
      .then(() => utils.notify('Input order updated'))
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.error === 'MatikAuthorizationError') {
          MAlert('You do not have permission to edit this template');
        } else {
          MAlert('There was an error updating the inputs order.');
        }
        setInputsOrder(inputsOrder);
      });
  };

  const disableDrag = (selectedSlides && selectedSlides.length > 0) || isDragDisabled;
  const loops = currentTemplate.slide_loops.concat(attachedTemplate?.slide_loops || []);
  return (
    <div className="component-params">
      <div className="filters-container mbs">
        <SearchBoxAndFilters searchState={searchState} searchAttributes={searchAttributes} filterPlaceholder="Search" />
      </div>
      {isSearching ? (
        <SearchedInputList
          inputsOrder={inputsOrder}
          inputsByName={inputsInMatchingContent}
          onClick={handleClick}
          onLoopClick={onLoopClick}
          loops={loops}
          template={currentTemplate}
          permissionIssuesByInput={permissionIssuesByInput}
        />
      ) : (
        <OrderableInputList
          inputsOrder={inputsOrder}
          onInputsOrderChange={handleInputsOrderChange}
          inputsByName={inputsInMatchingContent}
          onClick={handleClick}
          onLoopClick={onLoopClick}
          onHighlightSlides={highlightSlides}
          onUnhighlightSlides={unhighlightSlides}
          isDragDisabled={disableDrag}
          additionalInputs={{
            order: attachedTemplate?.params_order,
            inputsByName: attachmentInputsInMatchingContent,
          }}
          loops={loops}
          template={currentTemplate}
          permissionIssuesByInput={permissionIssuesByInput}
        />
      )}
    </div>
  );
};
SearchTemplateInputsList.propTypes = {
  inputsInMatchingContent: PropTypes.object,
  attachmentInputsInMatchingContent: PropTypes.object,
  isSearching: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
  currentTemplate: PropTypes.object,
  attachedTemplate: PropTypes.object,
  searchAttributes: PropTypes.object,
  searchState: PropTypes.object,
  selectedSlides: PropTypes.array,
  highlightSlides: PropTypes.func,
  unhighlightSlides: PropTypes.func,
  handleClick: PropTypes.func,
  onLoopClick: PropTypes.func,
};
SearchTemplateInputsList.displayName = 'TemplateInputsList.SearchTemplateInputsList';

export default WithInstantSearch(SearchTemplateInputsList);
