import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Pluralize from 'pluralize';
import Constants from 'components/Constants';
import AvatarGroup from 'components/lib/AvatarGroup';
import Button from 'components/lib/Button';
import DropdownMenu from 'components/lib/DropdownMenu';
import DropdownMenuItem from 'components/lib/DropdownMenuItem';
import Icon from 'components/lib/Icon';
import IconPill from 'components/lib/IconPill';
import ListItem from 'components/lib/ListItem';
import Connector from 'components/producer/conditions/Connector';
import ListItemDynamicContent from 'components/producer/dynamicContent/ListItemDynamicContent';
import { MConfirm } from 'components/shared/Alerts';
import { useAccessMutator } from 'lib/hooks/useAccess';

function AssetItemDynamicContent({ content, handleClick, onHighlight, onUnhighlight, permissionIssues }) {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const flags = useFlags();
  const templateAccessorsWithoutDynamicContentAccess = permissionIssues?.[content.matchingContent?.id] ?? [];
  const templateGroupAccessorsWithoutDynamicContentAccess = templateAccessorsWithoutDynamicContentAccess.filter(
    (accessor) => accessor.type === 'group',
  );
  const templateUserAccessorsWithoutDynamicContentAccess = templateAccessorsWithoutDynamicContentAccess.filter(
    (accessor) => accessor.type === 'user',
  );
  let grantAccessSubtitle = 'For';
  if (templateGroupAccessorsWithoutDynamicContentAccess.length) {
    grantAccessSubtitle += ` ${Pluralize('group', templateGroupAccessorsWithoutDynamicContentAccess.length, true)}`;
    if (templateUserAccessorsWithoutDynamicContentAccess.length) {
      grantAccessSubtitle += ' and';
    }
  }
  if (templateUserAccessorsWithoutDynamicContentAccess.length) {
    grantAccessSubtitle += ` ${Pluralize('user', templateUserAccessorsWithoutDynamicContentAccess.length, true)}`;
  }

  const { update: updateAccesses } = useAccessMutator();

  const onDropdownOpen = (e) => {
    e.stopPropagation();
    setIsDropdownActive(!isDropdownActive);
  };

  const handleAdd = () => {
    const accessors = templateAccessorsWithoutDynamicContentAccess.map((accessor) => ({
      accessor_type: accessor.type,
      accessor_id: accessor.id,
    }));
    const handleDataSourceAccessAdd = async (dataSourceId) => {
      if (dataSourceId > 0) {
        await updateAccesses(
          Constants.ItemTypes.DATA_SOURCE,
          [dataSourceId],
          Constants.PERMISSIONS.read.value,
          accessors,
        );
      }
    };
    MConfirm(
      'Updating access to relevant assets',
      'Granting users Read access to this dynamic content will also make them eligible for Read access to the associated data source and inputs.',
      'warning',
      (confirmed) => {
        if (confirmed) {
          handleDataSourceAccessAdd(content.matchingContent?.query_obj?.data_source?.id).then(() => {
            updateAccesses(
              Constants.ItemTypes.DYNAMIC_CONTENT,
              [content.matchingContent?.id],
              Constants.PERMISSIONS.read.value,
              accessors,
            );
          });
        }
      },
      'Update access',
    );
  };

  let connector;
  const isSubcontent = content.isChild && content.name !== content.matchingContent?.name;
  if (content.isChild) {
    if (content.lastChild) {
      connector = (
        <div className="flex">
          <Connector className="w-8 -mt-2" up right roundedUpRight />
          <Connector className="w-2 -mt-[10px]" left right />
        </div>
      );
    } else {
      connector = (
        <div className="flex">
          <Connector className="w-8 -mt-2" up right down roundedUpRight />
          <Connector className="w-2 -mt-[10px]" left right />
        </div>
      );
    }
  }
  let item;
  if (!content.matchingContent) {
    item = <ListItemDynamicContent content={content} subtitle="Does not exist" status="warning" />;
  } else if (isSubcontent) {
    item = (
      <ListItem
        icon={
          <IconPill
            iconName="subcontent"
            text={`Returns the ${content.name} field from ${content.matchingContent.name}`}
            size="s"
            theme="square"
          />
        }
        subtitle={content.formats}
        title={content.name}
      />
    );
  } else if (flags.dataSourcePermissions && templateAccessorsWithoutDynamicContentAccess.length) {
    item = (
      <ListItemDynamicContent
        content={content}
        subtitle="Access issues"
        status="error"
        metadata={<AvatarGroup size="s" limit={2} avatars={templateAccessorsWithoutDynamicContentAccess} />}
      />
    );
  } else if (flags.dataSourcePermissions && Object.keys(content.matchingContent).length === 2) {
    // {id,name} only means inaccessible content
    item = <ListItemDynamicContent content={content} subtitle="Access issues" status="error" />;
  } else {
    item = (
      <ListItemDynamicContent
        content={content.isChild ? { ...content.matchingContent, name: content.name } : content.matchingContent}
        subtitle={content.formats}
      />
    );
  }
  const contentName = content.toContentName();
  return (
    <div className="flex" onMouseEnter={() => onHighlight(content.slideNums, contentName)} onMouseLeave={onUnhighlight}>
      {connector}
      <div
        className="hover:border-matik-green border-grey-300 border rounded px-4 py-3 flex-1 cursor-pointer flex min-w-0"
        onClick={() => handleClick(content)}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        {item}
        {!!templateAccessorsWithoutDynamicContentAccess.length && isMouseOver && (
          <DropdownMenu
            dropdownTrigger={
              <Button category="tertiary" size="small" onClick={onDropdownOpen}>
                <Icon name="three_dots" size={20} theme="regular" color="matik-black" />
              </Button>
            }
            active={isDropdownActive}
            onClose={() => setIsDropdownActive(false)}
          >
            <DropdownMenuItem>
              <ListItem onClick={handleAdd} title="Grant Access" subtitle={grantAccessSubtitle} />
            </DropdownMenuItem>
          </DropdownMenu>
        )}
      </div>
    </div>
  );
}

AssetItemDynamicContent.propTypes = {
  content: PropTypes.object,
  handleClick: PropTypes.func,
  onHighlight: PropTypes.func,
  onUnhighlight: PropTypes.func,
  permissionIssues: PropTypes.object,
};

export default AssetItemDynamicContent;
